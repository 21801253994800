import ErrorPage from './error'
import DashboardPage from './dashboard'
import Question1Page from './question1'
import AnswerPage from './question1/answer'
import TestPage from './test/test'

export {
    ErrorPage,
    DashboardPage,
    Question1Page,
    AnswerPage,
    TestPage
}