import m from 'mithril'
import classNames from 'classnames'
import anime from 'animejs/lib/anime.es.js';
import {
    Manager
} from '@base/app/models'
import moment from 'moment'

export default class Question1Page {
    constructor() {
        this.id = m.route.param('id')
        this.menus = [
            { id: 1, title: "做基因檢測前，病友最關心的事", href: "1" },
            { id: 2, title: "基因檢測後，病友最在意的事", href: "2" },
            { id: 3, title: "病友如何知道基因檢測", href: "3" },
            { id: 4, title: "如果可以病友及家屬希望甚麼時候就要知道基因檢測", href: "4" },
            { id: 5, title: "當醫師推薦基因檢測時病友考慮執行的因素", href: "5" },
            { id: 6, title: "醫師眼裡的衛教師", href: "6" },
            { id: 7, title: "病患眼裡的衛教師", href: "7" },
            { id: 8, title: "你覺得病友團體哪項服務，對「醫護人員」較有幫助？", href: "8" },
            { id: 9, title: "癌症希望基金會所推出的工具中，那些對醫護人員最為實用？", href: "9" },
        ]

        this.questions = [
            [{ id: "a", title: "衛教說明" }, { id: "b", title: "檢測時間" }, { id: "c", title: "檢測費用" }],
            [{ id: "a", title: "報告解說" }, { id: "b", title: "用藥資訊" }, { id: "c", title: "治療效果" }],
            [{ id: "a", title: "醫師介紹" }, { id: "b", title: "病友團體介紹" }, { id: "c", title: "護理師建議" }, { id: "d", title: "網路上的資訊" }],
            [{ id: "a", title: "發現罹癌時" }, { id: "b", title: "治療效果不佳時" }, { id: "c", title: "癌症復發/移轉時" }],
            [{ id: "a", title: "家中經濟能力" }, { id: "b", title: "保險有沒有支付" }, { id: "c", title: "檢測公司的品質" }, { id: "d", title: "不考慮" }],
            [{ id: "a", title: "合作夥伴" }, { id: "b", title: "問題製造機" }, { id: "c", title: "沒有想法" }],
            [{ id: "a", title: "解決檢測前、中的疑難雜症的專業護理人員" }, { id: "b", title: "情緒抒發的心靈雞湯導師" }, { id: "c", title: "醫護間的重要溝通橋樑" }],
            [{ id: "a", title: "衛教提供的補位(ex.癌症治療、副作用、運動、營養...)" }, { id: "b", title: "提供衛教輔助工具(ex.手冊、衛教影片等)" }, { id: "c", title: "協助紓緩癌友情緒(ex.心理輔導、癌友與家屬諮詢...)" }, { id: "d", title: "協助連結社會資源連結(ex.保險、假髮、護具、補助申請...)" }, { id: "e", title: "沒有太大幫助" }],
            [{ id: "a", title: "癌症衛教手冊及單張" }, { id: "b", title: "衛教影片(主題EX.疾病衛教、自我照護、營養等...)" }, { id: "c", title: "病友自主管理工具-希望護照APP" }, { id: "d", title: "DM工具-攻略LINE機器人" }, { id: "e", title: "沒有太大幫助" }],
        ]

        this.select = []
        this.index = this.menus.findIndex(p => p.id == parseInt(this.id))
        this.total = 0

        Manager.load(this.id).then((response) => {
            this.total = response.count
        })


    }
    oncreate() {
        anime({
            targets: '.text-card-play',
            translateY: [-250, 0],
            direction: 'alternate',
            loop: false,
            delay: 100,
            endDelay: 100,
            duration: 1000,
        });
    }
    view() {
        return m('.container-fluid.text-card-play.h-100',{
            style:{
                padding:"2% 5%",
                background:"#FFF8A3"
            }
        }, [
            (!Manager.loading) ? "" :
                m(".loader-send", {
                    style: {
                        zIndex: "1024"
                    }
                }, [
                    m(".loader-block"),
                    m(".loader-block"),
                    m(".loader-block"),
                    m(".loader-block"),
                    m(".loader-block"),
                    m(".loader-block"),
                    m(".loader-block"),
                    m(".loader-block"),
                    m(".loader-block"),
                ]),
            m(".w-100",[
                m(".row.no-gutters.w-100.py-3", [
                    m(".col-12.d-flex.px-5.py-3.align-items-center.text-card-play", {
                        style: {
                            backgroundColor: "rgba(163, 216, 208, 0.3)",
                            borderRadius: "35px",
                            fontSize: "2.5rem"
                        }
                    }, [
                        m("", [
                            m(".rounded-circle.d-flex.justify-content-center.align-items-center", {
                                style: {
                                    backgroundColor: "#A3D8D0",
                                    width: "62px",
                                    height: "62px"
                                }
                            }, [
                                m("span.text-light.font-weight-bold", this.menus[this.index].id)
                            ])
                        ]),
                            m(".px-3", { style: { color: "rgba(67, 160, 146, 1)" } }, [
                            m("span", this.menus[this.index].title),
                        ]),


                    ]),
                    m(".col-12.py-2.text-card-play", {},
                        [
                            m(".d-flex.justify-content-between",{
                                style:{
                                    padding: "0 7%"
                                }
                            },[
                                m("span.font-weight-bold", {
                                    style: {
                                        fontSize: "1.5rem",
                                        color: (this.index >= 4) ? "red" : "#000",
                                        

                                    }
                                }, (this.index >= 4) ? "複選 / 請點選答案後按下一步觀看結果" : "單選 / 請點選答案後按下一步觀看結果"),

                                m(".px-5.py-2",{
                                    style:{
                                        backgroundColor:"rgba(255, 157, 0, 1)",
                                        borderRadius: "35px",
                                    }
                                },[
                                    m("span.font-weight-bold", {
                                        style: {
                                            fontSize: "1.5rem",
                                            color: "rgb(255,248,163)",
                                        }
                                    }, "填答人數"),
                                    m("span.font-weight-bold.pl-3.pr-5", {
                                        style: {
                                            fontSize: "1.5rem",
                                            color: "#FFF",
                                        }
                                    }, this.total),
                                ])
                                
                            ])
                            
                        ]),
                    m(".col-12.py-5.px-5.text-card-play.position-relative", {
                        style: {
                            backgroundColor: "rgba(255, 235, 0, 0.6)",
                            borderRadius: "50px",
                            fontSize: "3rem",
                            minHeight: "600px"
                        }
                    }, [
                         m("img.img-fuild", { src: "./img/nurse.png",style:{
                            position:"absolute",
                            top: "-28%",
                            right:"4%",
                            maxHeight:"168px"
                        } }),
                        m(`.row.no-gutters.w-100${(this.index == 7)?".pt-2":".pt-5" }`, [
                            this.questions[this.index].map((menu, index) => {
                                return m(".col-12.py-2.px-4.text-card-play", [
                                    m(`.row.no-gutters.w-100.h-100.py-1.px-4.d-flex.justify-content-center`, {
                                        style: {
                                            borderRadius: "30px",
                                            fontSize: "2rem",
                                            
                                        }
                                    }, [
                                        m(".col-1.d-flex.align-items-center.justify-content-end.px-3", [
                                            m(`span.d-flex.align-items-center.justify-content-center.pb-2`, {
                                                style: {
                                                    color: (this.select.includes(menu.id)) ? "#43A092" : "#FFF",
                                                    backgroundColor: (this.select.includes(menu.id)) ? "#FFF" : "#43A092",
                                                    borderRadius: "35px",
                                                    fontSize: "3rem",
                                                    width: "72px",
                                                    height: "72px"
                                                }
                                            }, menu.id)
                                        ]),
                                        m(`${(this.index >= 7)?".col-8":".col-7"}.d-flex.align-items.center.px-5.py-2`, {
                                            style: {
                                                backgroundColor: (this.select.includes(menu.id)) ? "#FFF" : "#A6DAD2",
                                                color: "#43A092",
                                                borderRadius: "40px",
                                            },
                                            onclick: (e) => {
                                                e.preventDefault()
                                                e.stopPropagation()
                                                if (this.index >= 4) {
                                                    if (this.select.includes(menu.id)) {
                                                        this.select.splice(this.select.indexOf(menu.id), 1)
                                                    } else {
                                                        this.select.push(menu.id)
                                                    }
                                                } else {
                                                    this.select = [menu.id]
                                                }

                                                anime.timeline({
                                                    easing: 'easeInOutQuad',
                                                    direction: 'alternate',
                                                    loop: false
                                                })
                                                    .add({ targets: `.color-hex-${menu.id}`, background: '#FFF' }, 0)
                                                    .add({ targets: `.color-hex-${menu.id}-title`, color: '#C3E6E1' }, 0)
                                            },
                                        }, [
                                            m("span.d-block.font-weight-bold", menu.title),
                                        ]),
                                        m(".col-3")
                                    ])
                                ])
                            })
                        ])
                    ]),
                    m(".col-12.px-5.py-4", [
                        m(".row.no-gutters.w-100.d-flex.justify-content-between", [
                            m(".col-4.d-flex.justify-content-between.px-5.py-2.align-items-center", {
                                style: {
                                    fontSize: "2rem",
                                    backgroundColor: "#A3D8D0",
                                    color: "#FFF",
                                    borderRadius: "50px",
                                },
                                onclick: (e) => {
                                    e.preventDefault()
                                    m.route.set("/")
                                }
                            }, [
                                m("i.fas.fa-caret-left", { style: { fontSize: "2.8rem" } }),
                                m("span.font-weight-bold", "回上層")
                            ]),
                            m(".col-4.d-flex.justify-content-between.px-5.py-2.align-items-center", {
                                style: {
                                    fontSize: "2rem",
                                    backgroundColor: "#A3D8D0",
                                    color: "#FFF",
                                    borderRadius: "50px",
                                }
                                ,
                                onclick: (e) => {
                                    e.preventDefault()
                                    if (this.select.length == 0) {
                                        let message = (this.index >= 4) ? "請至少選擇一個選項唷~" : "請選擇一個選項唷~"
                                        swal("哎呦……", message, "error")
                                    } else {



                                        Manager.save(this.id, this.select).then((response) => {
                                            m.route.set(`/${this.menus[this.index].id}/answer`)
                                        })
                                    }
                                }
                            }, [
                                m("span.font-weight-bold", "下一步"),
                                m("i.fas.fa-caret-right", { style: { fontSize: "2.8rem" } }),

                            ]),
                        ])

                    ])
                ])
            ]),
        ])
    }
}