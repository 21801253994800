import m from 'mithril'
import classNames from 'classnames'
import anime from 'animejs/lib/anime.es.js';
import {
    Manager
} from '@base/app/models'
import moment from 'moment'

export default class TestPage {
    constructor() {
        this.datas = []
        Manager.fetch().then((response) => {
            this.datas = response
            console.log(response)
        })
    }

    view() {
        return m('.container-fluid.text-card-play', {
            style: {
                padding: "2% 20%",
            }
        }, [
            m(".d-flex.justify-content-end.py-3", [
                m("span.btn.btn-outline-primary",{
                    onclick:(e)=>{
                        e.preventDefault()
                        e.stopPropagation()
                        let url = `./api/test/export`                      
                        window.location.href = url




                    }
                }, "匯出")
            ]),
            m("table.table.table-hover.pt-3.border",
                [
                    m("thead",
                        m("tr",
                            [
                                m("th[scope='col']",
                                    "#"
                                ),
                                m("th[scope='col']",
                                    "總人數"
                                ),
                                m("th[scope='col']",
                                    "A"
                                ),
                                m("th[scope='col']",
                                    "B"
                                ),
                                m("th[scope='col']",
                                    "C"
                                ),
                                m("th[scope='col']",
                                    "D"
                                ),
                                m("th[scope='col']",
                                    "E"
                                ),
                            ]
                        )
                    ),
                    m("tbody",
                        [
                            this.datas.map((_data) => {
                                return m("tr",
                                    [
                                        m("th[scope='row']",
                                            `第 ${_data.question} 題`
                                        ),
                                        m("td",
                                            _data.count
                                        ),
                                        m("td",
                                            _data.a
                                        ),
                                        m("td",
                                            _data.b
                                        ),
                                        m("td",
                                            _data.c
                                        ),
                                        m("td",
                                            _data.d
                                        ),
                                        m("td",
                                            _data.e
                                        ),
                                    ]
                                )
                            })
                        ]
                    )
                ]
            )
        ])
    }
}