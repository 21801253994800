import m from 'mithril'
import validate from 'validate.js'
import moment from 'moment'
import uuid from 'uuid/v1'

validate.validators.uniqueCard = (value, options, key, attributes) => {
    let valid = true
    let _cards = []
    const objs = options.cards().filter(p => p == value)
    if (objs.length > 1) {
        valid = false
    }
    return (valid) ? undefined : options.message
};

const identityValidator = (value) => {
    const a = new Array('A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'X', 'Y', 'W', 'Z', 'I', 'O');
    const b = new Array(1, 9, 8, 7, 6, 5, 4, 3, 2, 1);
    const c = new Array(2);

    let d, e, f, g = 0,
        h = /^[a-z](1|2)\d{8}$/i;

    if (value.search(h) == -1) {
        return false;
    } else {
        d = value.charAt(0).toUpperCase();
        f = value.charAt(9);
    }
    for (let i = 0; i < 26; i++) {
        if (d == a[i]) //a==a
        {
            e = i + 10; //10
            c[0] = Math.floor(e / 10); //1
            c[1] = e - (c[0] * 10); //10-(1*10)
            break;
        }
    }
    for (let i = 0; i < b.length; i++) {
        if (i < 2) {
            g += c[i] * b[i];
        } else {
            g += parseInt(value.charAt(i - 1)) * b[i];
        }
    }
    if ((g % 10) == f) {
        return true;
    }
    if ((10 - (g % 10)) != f) {
        return false;
    }
    return true;
}

validate.validators.identityROC = (value, options, key, attributes) => {
    const valid = identityValidator(value)
    return (valid) ? undefined : options.message
};

let _DEFAULT_GUID = "00000000-0000-0000-0000-000000000000"
let _loading = false
let _language

class BaseModel {

    constructor(args) {
        this.constraints = args
        this.errors = []
    }

    static get DEFAULT_GUID() {
        return _DEFAULT_GUID
    }

    static get loading() {
        return _loading
    }

    static set loading(value) {
        _loading = value
    }

    static get language() {
        return _language
    }

    static set language(value) {
        _language = value
    }

    static maskvalue(value, regex) {
        const maskObject = conformToMask(value, regex, { guide: false })
        return maskObject.conformedValue
    }

    static padLeft(str, len) {
        str = '' + str;
        if (str.length >= len) {
            return str;
        } else {
            return this.padLeft("0" + str, len);
        }
    }

    uuid() {
        return uuid()
    }

    identityValidator(value) {
        return identityValidator(value)
    }

    valid(field) {
        if (this.constraints ==undefined || this.constraints[field] == undefined) {
            return null
        }
        let error = validate.single(this[field], this.constraints[field])
        this.errors = this.errors || []
        this.errors[field] = error
        return error ? error[0] : null
    }
    error(field) {
        return (this.errors && this.errors[field]) ? this.errors[field][0] : null
    }
    validate() {
        const data = this
        this.errors = validate(data, this.constraints)
        return this.errors || false
    }
    clear(object) {
        if (object.hasOwnProperty('data')) {
            delete object.data
        }
        return JSON.parse(JSON.stringify(object))
    }

    ajaxError(e) {
        const msg = JSON.parse(e)
        if (msg.hasOwnProperty('Error')) {
            return msg.Error.toString()
        }
        let errros = []
        Object.keys(msg).map(function(objectKey, index) {
            var value = msg[objectKey];
            errros.push(value)
        })
        return (errors) ? errors.toString() : false
    }
}

export default BaseModel