import m from 'mithril'
import classNames from 'classnames'
import anime from 'animejs/lib/anime.es.js';
import {
    Manager
} from '@base/app/models'
import moment from 'moment'

export default class AnswerPage {
    constructor() {
        this.id = m.route.param('id')

        this.menus = [
            { id: 1, title: "做基因檢測前，病友最關心的事", href: "1" },
            { id: 2, title: "基因檢測後，病友最在意的事", href: "2" },
            { id: 3, title: "病友如何知道基因檢測", href: "3" },
            { id: 4, title: "如果可以病友及家屬希望甚麼時候就要知道基因檢測", href: "4" },
            { id: 5, title: "當醫師推薦基因檢測時病友考慮執行的因素", href: "5" },
            { id: 6, title: "醫師眼裡的衛教師", href: "6" },
            { id: 7, title: "病患眼裡的衛教師", href: "7" },
            { id: 8, title: "你覺得病友團體哪項服務，對「醫護人員」較有幫助？", href: "8" },
            { id: 9, title: "癌症希望基金會所推出的工具中，那些對醫護人員最為實用？", href: "9" },
        ]
        this.questions = [
            [{ id: "a", title: "衛教說明", value: 0, width: "0%" }, { id: "b", title: "檢測時間", value: 0, width: "0%" }, { id: "c", title: "檢測費用", value: 0, width: "0%" }],
            [{ id: "a", title: "報告解說", value: 0, width: "0%" }, { id: "b", title: "用藥資訊", value: 0, width: "0%" }, { id: "c", title: "治療效果", value: 0, width: "0%" }],
            [{ id: "a", title: "醫師介紹", value: 0, width: "0%" }, { id: "b", title: "病友團體介紹", value: 0, width: "0%" }, { id: "c", title: "護理師建議", value: 0, width: "0%" }, { id: "d", title: "網路上的資訊", value: 0, width: "0%" }],
            [{ id: "a", title: "發現罹癌時", value: 0, width: "0%" }, { id: "b", title: "治療效果不佳時", value: 0, width: "0%" }, { id: "c", title: "癌症復發/移轉時", value: 0, width: "0%" }],
            [{ id: "a", title: "家中經濟能力", value: 0, width: "0%" }, { id: "b", title: "保險有沒有支付", value: 0, width: "0%" }, { id: "c", title: "檢測公司的品質", value: 0, width: "0%" }, { id: "d", title: "不考慮", value: 0, width: "0%" }],
            [{ id: "a", title: "合作夥伴", value: 0, width: "0%" }, { id: "b", title: "問題製造機", value: 0, width: "0%" }, { id: "c", title: "沒有想法", value: 0, width: "0%" }],
            [{ id: "a", title: "解決檢測前、中的疑難雜症的專業護理人員", value: 0, width: "0%" }, { id: "b", title: "情緒抒發的心靈雞湯導師", value: 0, width: "0%" }, { id: "c", title: "醫護間的重要溝通橋樑", value: 0, width: "0%" }],
            [{ id: "a", title: "衛教提供的補位(ex.癌症治療、副作用、運動、營養...)", value: 0, width: "0%" }, { id: "b", title: "提供衛教輔助工具(ex.手冊、衛教影片等)", value: 0, width: "0%" }, { id: "c", title: "協助紓緩癌友情緒(ex.心理輔導、癌友與家屬諮詢...)", value: 0, width: "0%" }, { id: "d", title: "協助連結社會資源連結(ex.保險、假髮、護具、補助申請...)", value: 0, width: "0%" }, { id: "e", title: "沒有太大幫助", value: 0, width: "0%" }],
            [{ id: "a", title: "癌症衛教手冊及單張", value: 0, width: "0%" }, { id: "b", title: "衛教影片(主題EX.疾病衛教、自我照護、營養等...)", value: 0, width: "0%" }, { id: "c", title: "病友自主管理工具-希望護照APP", value: 0, width: "0%" }, { id: "d", title: "DM工具-攻略LINE機器人", value: 0, width: "0%" }, { id: "e", title: "沒有太大幫助", value: 0, width: "0%" }],
        ]
        this.analizations = [
            [{ id: "a", title: "衛教說明", value: 50, width: "50%" }, { id: "b", title: "檢測時間", value: 20, width: "20%" }, { id: "c", title: "檢測費用", value: 30, width: "30%" }],
            [{ id: "a", title: "報告解說", value: 50, width: "50%" }, { id: "b", title: "用藥資訊", value: 30, width: "30%" }, { id: "c", title: "治療效果", value: 20, width: "20%" }],
            [{ id: "a", title: "醫師介紹", value: 40, width: "40%" }, { id: "b", title: "病友團體介紹", value: 30, width: "30%" }, { id: "c", title: "護理師建議", value: 10, width: "10%" }, { id: "d", title: "網路上的資訊", value: 20, width: "20%" }],
            [{ id: "a", title: "發現罹癌時", value: 55, width: "55%" }, { id: "b", title: "治療效果不佳時", value: 25, width: "25%" }, { id: "c", title: "癌症復發/移轉時", value: 20, width: "20%" }],
            [{ id: "a", title: "家中經濟能力", value: 80, width: "80%" }, { id: "b", title: "保險有沒有支付", value: 56, width: "56%" }, { id: "c", title: "檢測公司的品質", value: 37, width: "37%" }, { id: "d", title: "不考慮", value: 5, width: "5%" }],
        ]
        this.replys = [
            '病友在衛教說明的期間，最常問的問題有：檢測費用、檢測目的以及多久能拿到報告。平均一位病患，需要花約15天的溝通期，才開始進行檢測；臨床經驗告訴我們，每位病患及家屬，都需要超過1個小時的面對面諮詢。',
            '多數病友在後續衛教關懷中提及收到報告後，對於報告有更多想要瞭解的地方，卻有很大部分的機率是無從得知。',
            '現在的病友，有很多方式可以認識基因檢測。在面對病友的衛教過程中，我們發現雖然現在資訊發達，但是訊息過於片面、難懂，且有進一步問題時很難再度詢問，造成病友及家屬的不安。',
            '對於病患及家屬來說，提早知道有基因檢測並不是件壞事，反而能讓他們知道有什麼武器可以面對接下來的病程，經由共同決策的過程，建立緊密醫病溝通的渠道。',
            '超過120位病友訪談，每位可以選擇最注重的2個議題時，有許多病友反應經濟問題是病友最關心的議題。在衛教說明期間，也有很高的比例的病友及家屬知道基因檢測對他的病程是有幫助的，但費用仍是他們考量的重點。因此如何降低病友的開銷，就是一個很大的重點。'

        ]
        this.foucus = false
        this.index = this.menus.findIndex(p => p.id == parseInt(this.id))

        this.total = 0

        Manager.load(this.id).then((response) => {
            this.questions[this.index][0].value = parseInt(response.a / response.total * 100)
            this.questions[this.index][0].width = (this.questions[this.index][0].value) + "%"
            this.questions[this.index][1].value = parseInt(response.b / response.total * 100)
            this.questions[this.index][1].width = (this.questions[this.index][1].value) + "%"
            this.questions[this.index][2].value = parseInt(response.c / response.total * 100)
            this.questions[this.index][2].width = (this.questions[this.index][2].value) + "%"
            if (this.questions[this.index].length > 3) {
                this.questions[this.index][3].value = parseInt(response.d / response.total * 100)
                this.questions[this.index][3].width = (this.questions[this.index][3].value) + "%"
            }
            if (this.questions[this.index].length > 4) {
                this.questions[this.index][4].value = parseInt(response.e / response.total * 100)
                this.questions[this.index][4].width = (this.questions[this.index][4].value) + "%"
            }
            this.questions[this.index].map((item) => {
                anime.timeline().add({ targets: `.width-to-0-${item.id}`, width: item.width, easing: 'easeInOutQuad', direction: 'alternate' }, 0)
                anime.timeline().add({ targets: `.number-to-0-${item.id}`, innerHTML: [0, item.value], easing: 'linear', round: 1 }, 0)
            })

            this.total = response.count
        })
    }
    oncreate() {
        anime.timeline({
            easing: 'easeInOutQuad',
            direction: 'alternate',
            loop: false,
            duration: 500,
            delay: 100,
        })


        anime({
            targets: '.text-card-play',
            translateY: [-250, 0],
            direction: 'alternate',
            loop: false,
            delay: 100,
            endDelay: 100,
            duration: 1000,
        });
    }
    view() {
        return m('.container-fluid.text-card-play.h-100', {
            style: {
                padding: "2% 5%",
                background: "#FFF8A3"
            }
        }, [
            m(".w-100", [
                m(".row.no-gutters.w-100.py-3", [
                    m(".col-12.d-flex.px-5.py-3.align-items-center", {
                        style: {
                            fontSize: "2.5rem"
                        }
                    }, [
                        m("", [
                            m(".rounded-circle.d-flex.justify-content-center.align-items-center", {
                                style: {
                                    backgroundColor: "#A3D8D0",
                                    width: "62px",
                                    height: "62px"
                                }
                            }, [
                                m("span.text-light.font-weight-bold", this.menus[this.index].id)
                            ])
                        ]),
                        m(".px-3"),
                        m("", {
                            style: {
                                color: "#43A092",
                                borderBottom: "7px #C8E7E3 solid",
                                fontSize: "2.5rem"
                            }
                        }, [
                            m("span", this.menus[this.index].title),
                        ]),


                    ]),
                    //分析
                    m(".col-12.pt-4.pb-4.position-relative", {},
                        [
                            m(".position-absolute", {
                                style: {
                                    top: "0%",
                                    right: "5%"
                                }
                            }, [

                                m(".px-5.py-2", {
                                    style: {
                                        backgroundColor: "rgba(255, 157, 0, 1)",
                                        borderRadius: "35px",
                                    }
                                }, [
                                    m("span.font-weight-bold", {
                                        style: {
                                            fontSize: "1.5rem",
                                            color: "rgb(255,248,163)",
                                        }
                                    }, "填答人數"),
                                    m("span.font-weight-bold.pl-3.pr-5", {
                                        style: {
                                            fontSize: "1.5rem",
                                            color: "#FFF",
                                        }
                                    }, this.total),
                                ]),
                                // m("span.font-weight-bold.pl-3", { style: { color: "#43A092", fontSize: "1.5rem" } }, "")
                            ]),
                            m(".position-absolute", {
                                style: {
                                    bottom: "40%",
                                    right: "1%"
                                }
                            }, [
                                m("img.img-fuild", {
                                    src: (this.foucus) ? "./img/s15.png" : "./img/doctor.png",
                                    style: {
                                        maxHeight: "168px"

                                    }
                                }),
                            ]),
                            m(".row.no-gutters.w-100", {
                                style: {
                                    padding: "0 8%"
                                }
                            }, [
                                m(".col-5.d-flex.align-items-end", [
                                    m("img.img-fuild", {
                                        src: "./img/129.png",
                                    }),
                                    m("span.font-weight-bold.pl-3", { style: { color: "#43A092", fontSize: "1.5rem" } }, "現場專家回饋")
                                ]),

                                m(".col-7.check_opacity.d-flex.align-items-end", {
                                    style: {
                                        opacity: 0,
                                        padding: "0 6%"
                                    }
                                }, [
                                    m("img.img-fuild", {
                                        src: "./img/b94.png",
                                    }),
                                    m("span.font-weight-bold.pl-3", { style: { color: "#FFA40C", fontSize: "1.5rem" } }, "病友回饋")
                                ])
                            ]),
                        ]
                    ),
                    m(`${(this.index >= 7) ?".col-8":".col-5"}`, {
                        style: {
                            padding: "0 0 0 5%"
                        }
                    }, [
                        m(`${(this.index >= 7) ?".p-3":".p-5"}.text-card-play`, {
                            style: {
                                backgroundColor: "rgba(255, 235, 0, 0.6)",
                                borderRadius: "50px",
                                fontSize: "3rem",
                                minHeight: "550px",
                                maxHeight: "550px"
                            }
                        }, [
                            m(".row.no-gutters.w-100", [
                                m(".col-12", [
                                    this.questions[this.index].map((menu, index) => {
                                        return m(`.col-12${(this.index >= 7) ? ".py-0" : ".py-2"}`, [
                                            m(`.row.no-gutters.w-100.h-100.py-1.d-flex`, {
                                                style: {
                                                    borderRadius: "30px",
                                                    fontSize: "1.5rem",
                                                },
                                            }, [
                                                m(`${(this.index >= 7) ?".col-1":".col-2"}.d-flex.align-items-center.justify-content-end.px-3`, [
                                                    m(`span.d-flex.align-items-center.justify-content-center.pb-2`, {
                                                        style: {
                                                            color: "#FFF",
                                                            backgroundColor: "#A5D9D1",
                                                            borderRadius: "35px",
                                                            fontSize: "2rem",
                                                            width: "45px",
                                                            height: "45px"
                                                        }
                                                    }, menu.id)
                                                ]),
                                                m(`${(this.index >= 7) ?".col-11":".col-10"}.d-flex.align-items.center`, {
                                                    style: {
                                                        color: "#43A092",
                                                    }
                                                }, [
                                                    m("span.d-block.font-weight-bolder", menu.title),
                                                ]),
                                                m(`${(this.index >= 7) ?".col-2":".col-5"}.d-flex.align-items-center.justify-content-end`, [
                                                    m(`span.pr-3.number-to-0-${menu.id}`, {
                                                        style: {
                                                            color: "#BA967F",
                                                            fontSize: "2rem"
                                                        }
                                                    }, menu.value.toFixed(0) + "%"),
                                                ]),
                                                m(`${(this.index >= 7) ?".col-10":".col-7"}.d-flex.align-items-center`, [
                                                    m(`.d-inline-block.width-to-0-${menu.id}`, {
                                                        style: {
                                                            backgroundColor: "rgba(163, 216, 208, 1)",
                                                            height: "70%"
                                                            // width: menu.width
                                                        }
                                                    })
                                                ]),
                                            ])
                                        ])
                                    })
                                ]),
                            ])
                        ]),
                    ]),

                    (this.index < 5) ? [
                        m(".col-5.pl-5.text-card-play", [
                            m(".py-5.px-5.check_opacity", {
                                style: {
                                    backgroundColor: "rgb(255,222,116)",
                                    borderRadius: "50px 0 0 50px",
                                    fontSize: "3rem",
                                    minHeight: "550px",
                                    maxHeight: "550px",
                                    opacity: 0
                                }
                            }, [
                                m(".row.no-gutters.w-100", {
                                    style: {
                                        // opacity: 0
                                    }
                                }, [
                                    this.analizations[this.index].map((menu, index) => {
                                        return m(".col-12.py-2", [
                                            m(`.row.no-gutters.w-100.h-100.py-1`, {
                                                style: {
                                                    borderRadius: "30px",
                                                    fontSize: "1.5rem",
                                                },
                                            }, [
                                                m(".col-2.d-flex.align-items-center.justify-content-end.px-3", [
                                                    m(`span.d-flex.align-items-center.justify-content-center.pb-2`, {
                                                        style: {
                                                            color: "#000",
                                                            backgroundColor: "#FF9D00",
                                                            borderRadius: "35px",
                                                            fontSize: "2rem",
                                                            width: "45px",
                                                            height: "45px"
                                                        }
                                                    }, menu.id)
                                                ]),
                                                m(".col-10.d-flex.align-items.center", {
                                                    style: {
                                                        color: "#000",
                                                    }
                                                }, [
                                                    m("span.d-block.font-weight-bold", menu.title),
                                                ]),
                                                m(".col-3"),
                                                m(".col-8.d-flex.align-items-center", [
                                                    m(`span.pr-3.number-to-100-${menu.id}`, {
                                                        style: {
                                                            color: "#000",
                                                            fontSize: "2rem"
                                                        }
                                                    }, menu.value.toFixed(0) + "%"),
                                                    m(`.d-inline-block.width-to-100-${menu.id}`, {
                                                        style: {
                                                            backgroundColor: "#FF9D00",
                                                            height: "70%"
                                                            // width: `${600 * menu.value / 100}px`
                                                        }
                                                    })
                                                ])
                                            ])
                                        ])
                                    })
                                ])
                            ])
                        ]),
                        m(".col-2.text-card-play", [
                            m(".py-5.check_opacity", {
                                style: {
                                    backgroundColor: "rgba(255, 157, 0, 0.7)",
                                    borderRadius: "0 50px 50px 0",
                                    fontSize: "3rem",
                                    minHeight: "550px",
                                    maxHeight: "550px",
                                    opacity: 0
                                }
                            }, [
                                m(".row.no-gutters.w-100", {
                                    style: {
                                        // opacity: 0
                                    }
                                }, [

                                    // m(".col-12.pb-3", { style: { color: "#43A092", borderBottom: "7px #C8E7E3 solid", fontSize: "2rem" } }, [
                                    //     m("i.fas.fa-file-alt"),
                                    //     m("span.font-weight-bold.pl-3", "病友回饋")
                                    // ]),
                                    m(".col-12.py-3.px-5", [
                                        m("p.font-weight-bold", { style: { fontSize: "1.2rem", textAlign: "justify" } }, this.replys[this.index])
                                    ])
                                ])
                            ])
                        ]),
                    ] : "",
                    m(".col-12.px-5.py-4", [
                        m(".row.no-gutters.w-100.d-flex.justify-content-end", [
                            m(".col-4.d-flex.justify-content-between.px-5.py-3.align-items-center", {
                                style: {
                                    fontSize: "2rem",
                                    backgroundColor: (this.foucus || this.index >= 5) ? "#FF9D00" : "#A3D8D0",
                                    color: "#FFF",
                                    borderRadius: "50px",
                                },
                                onclick: (e) => {
                                    e.preventDefault()
                                    e.stopPropagation()

                                    if (!this.foucus) {
                                        this.foucus = !this.foucus
                                    } else {
                                        m.route.set("/")
                                    }

                                    if (this.index >= 5) {
                                        m.route.set("/")
                                    }

                                    var _temp = document.querySelectorAll(".check_opacity")
                                    Array.prototype.slice.call(_temp).map((_item) => {
                                        _item.style.opacity = 1
                                    })

                                    anime.timeline({
                                        easing: 'easeInOutQuad',
                                        direction: 'alternate',
                                        loop: false,
                                        duration: 1500
                                    })
                                    // .add({ targets: `.open-result-a`, background: 'rgb(255,222,116)' }, 0)
                                    // .add({ targets: `.open-result-b`, background: 'rgba(255, 157, 0, 0.7)' }, 0)
                                    // .add({ targets: `.opacity-to-100-a`, opacity: 1, translateY: 0, easing: 'linear', offset: 100 }, 0)
                                    // .add({ targets: `.opacity-to-100-b`, opacity: 1, translateY: 0, easing: 'linear', offset: 100 }, 0)

                                    this.analizations[this.index].map((item) => {
                                        anime.timeline().add({ targets: `.width-to-100-${item.id}`, width: item.width, easing: 'easeInOutQuad', direction: 'alternate', duration: 1500 }, 10)
                                        anime.timeline().add({ targets: `.number-to-100-${item.id}`, innerHTML: [0, item.value], easing: 'linear', round: 1 }, 0)
                                    })


                                }
                            }, [
                                m("span.font-weight-bold", (this.foucus || this.index >= 5) ? "回首頁" : "看比較"),
                                m("i.fas.fa-caret-right", { style: { fontSize: "2.8rem", color: (this.foucus || this.index >= 5) ? "#FFF" : "#FFF" } }),

                            ]),
                        ])

                    ])
                ])
            ]),
        ])
    }
}