import m from 'mithril'
import classNames from 'classnames'
import anime from 'animejs/lib/anime.es.js';

import moment from 'moment'

export default class DashboardPage {
    constructor() {
        this.menus = [
            { id: 1, title: "做基因檢測前", subtitle: "病友最關心的事", href: "1" },
            { id: 2, title: "基因檢測後", subtitle: "病友最在意的事", href: "2" },
            { id: 3, title: "病友如何知道", subtitle: "基因檢測", href: "3" },
            { id: 4, title: "如果可以", subtitle: "病友及家屬希望甚麼時候就要知道基因檢測", href: "4" },
            { id: 5, title: "當醫師推薦基因檢測時", subtitle: "病友考慮執行的因素", href: "5" },
            { id: 6, title: "醫師", subtitle: "眼裡的衛教師", href: "6" },
            { id: 7, title: "病患", subtitle: "眼裡的衛教師", href: "7" },
            { id: 8, title: "你覺得病友團體", subtitle: "哪項服務，對「醫護人員」較有幫助？", href: "8" },
            { id: 9, title: "癌症希望基金會", subtitle: "所推出的工具中，那些對醫護人員最為實用？", href: "9" },
        ]
        this.foucus = false
    }
    oncreate() {
        anime({
            targets: '.text-card-play',
            translateY: [-250, 0],
            direction: 'alternate',
            loop: false,
            delay: 100,
            endDelay: 100,
            duration: 1000,
        });
        this.menus.map((item) => {
            anime({
                targets: `.card-play-fadeIn-${item.id}`,
                translateX: [2080, 0],
                direction: 'alternate',
                loop: false,
                delay: 100 * item.id,
                endDelay: 100 * item.id,
                duration: 500,
            });
        })

    }
    view() {
        return m('.container-fluid.text-card-play.h-100', {
            style: {
                padding: "2% 5%",
                background: "#FFF8A3"
            }
        }, [
            m(".w-100", [
                m(".row.no-gutters.w-100.py-3.text-card-play", [
                    m(".col-12.d-flex.justify-content-between.px-5.py-2.align-items-center", {
                        style: {
                            backgroundColor: "#FFEB00",
                            borderRadius: "25px",
                            fontSize: "3rem"
                        }
                    }, [
                        m("", { style: { color: "#43A092" } }, [
                            m("span", "病友最注意"),
                            m("span.font-weight-bold", "9大問題"),
                        ]),
                        m("",{
                            style:{
                                padding:"0 70px"
                            }
                        }, [
                            m(".rounded-circle.d-flex.justify-content-center.align-items-center", {
                                style: {
                                    backgroundColor: "#A3D8D0",
                                    width: "92px",
                                    height: "92px"
                                }
                            }, [
                                m("i.fas.fa-question.text-light")
                            ])
                        ])

                    ]),
                    m(".col-12.py-4"),
                    m(".col-12.py-5.px-2.text-card-play.position-relative", {
                        style: {
                            backgroundColor: "#FFEB00",
                            borderRadius: "25px",
                            fontSize: "3rem"
                        }
                    }, [
                        m("img.img-fuild", { src: "./img/s15.png",style:{
                            position:"absolute",
                            top: "-24%",
                            right:"2%",
                            maxHeight:"161px"
                        } }),
                        m(".row.no-gutters.w-100", [
                            this.menus.map((menu, index) => {
                                return m(`.col-4.py-3.px-4.card-play-fadeIn-${menu.id}`, [
                                    m(`.row.no-gutters.w-100.h-100.py-2.px-4.color-hex-${menu.id}`, {
                                        style: {
                                            borderRadius: "25px",
                                            fontSize: "2rem",
                                            minHeight: "160px",
                                            backgroundColor: "#FFF"
                                        },
                                        onclick: (e) => {
                                            e.preventDefault()
                                            e.stopPropagation()

                                            anime.timeline({
                                                easing: 'easeInOutQuad',
                                                direction: 'alternate',
                                                loop: false,
                                                duration: 500,
                                            })
                                                .add({ targets: `.color-hex-${menu.id}`, background: '#FFF' }, 0)
                                                .add({ targets: `.color-hex-${menu.id}-title`, color: '#C3E6E1' }, 0)

                                            m.route.set(menu.href)
                                        },
                                        onmouseout: (e) => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                            // anime.timeline({
                                            //     easing: 'easeInOutQuad',
                                            //     direction: 'alternate',
                                            //     loop: false
                                            // })
                                            //     .add({ targets: `.color-hex-${menu.id}`, background: '#C3E6E1' }, 0)
                                            //     .add({ targets: `.color-hex-${menu.id}-title`, color: '#FFF' }, 0)
                                        }
                                    }, [
                                        m(".col-3", [
                                            m(`span.font-weight-bold.px-3.color-hex-${menu.id}-title`, {
                                                style: {
                                                    fontSize: "8rem",
                                                    lineHeight: "7rem",
                                                    color: "#C3E6E1"
                                                }
                                            }, menu.id),
                                            m(".d-inline-block",
                                                {
                                                    style: {
                                                        // color: "#43A092",
                                                        fontSize: "8rem",
                                                        lineHeight: "7rem",
                                                        transform: "rotate(5deg)",
                                                        background: "#43A092",
                                                        width: "3px",
                                                        height: "6rem"
                                                    }
                                                }
                                            )
                                        ]),
                                        m(".col-9", {
                                            style: {
                                                color: "#43A092"
                                            }
                                        }, [
                                            m("span.d-block.font-weight-bold", menu.title),
                                            m("p.d-block", { style: { lineHeight: "2rem" } }, menu.subtitle),
                                        ])
                                    ])
                                ])
                            })

                        ])

                    ])
                ])
            ]),
        ])
    }
}